<template>
<div>
    <div class="wrap">
        <div class="con_top">
            <h2><span>■</span> 관리자계정</h2>
        </div>

        <div class="con">
            <div class="con_table">
                <div class="btns mb-30">
                    <a class="btn_new2 pointer" @click="moveAccountRegister()">관리자등록</a>
                </div>
                <table class="default_table">
                    <tbody v-if="loading">
                        <tr>
                            <th>No</th>
                            <th>이름</th>
                            <th>아이디</th>
                        </tr>
                        <tr>
                            <td colspan="3">검색중입니다.</td>
                        </tr>
                    </tbody>
                    <tbody v-else> 
                        <tr>
                            <th>No</th>
                            <th>이름</th>
                            <th>아이디</th>
                        </tr>
                        <tr v-for="(item, index) of adminList" :key="index">
                            <td>{{item.rownum}}</td>
                            <td class="underline pointer" @click="moveAccountModify(item.idx)">{{item.name}}</td>
                            <td>{{item.id}}</td>
                            <!-- <td><span class="underline pointer" @click="moveAccountModify(item.idx)">수정</span> | <span class="underline pointer" @click="remove(item.idx)">삭제</span></td> -->
                        </tr>
                        <tr v-if="adminList.length === 0">
                            <td colspan="3">검색 결과가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === page}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        adminList: [],
        page: 1,
        pagesize: 50,

        // 페이징에 필요한 변수들
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
    }),

    mounted() {
        this.list();
    },

    methods: {

        // 이전 페이징
        prevPaging() {
            if (this.page === 1) {
                return false
            } else {
                this.page -= 1
                this.list()
            }
        },
        paging(index) {
            this.page = index
            this.list()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.page === this.maxPagingCount) {
                return false
            } else {
                this.page += 1
                this.list()
            }
        },

        // 관리자 등록 페이지 이동
        moveAccountRegister() {
            this.$router.push('/admin/account/register')
        },

        // 관리자 수정 페이지 이동
        moveAccountModify(idx) {
            this.$router.push('/admin/account/modify/' + idx)
        },

        // 관리자계정 삭제
        remove(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                let param = {idx: idx}
                this.axios.post("/api/v1/solution/deldata/crm_staff", param).then((res) => {
                    if(res.data.err === 0) {
                        alert('삭제되었습니다.')
                        this.$router.go(this.$router.currentRoute)
                    } else {
                        alert(res.data.err_msg);
                    }
                });
            } else {
                return false
            }
        },

        list() {
          var param = {
              page: this.page,
              pagesize: this.pagesize
          }
          this.loading = true;
          this.axios.get("/api/v1/admin/list", {params:param}).then((res) => {
            if(res.data.err === 0) {
              this.adminList = res.data.list;
                if (res.data.size) {
                    this.listLength = res.data.size
                }   
                this.maxPagingCount = Math.ceil(this.listLength / this.pagesize)
                this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                this.pagePart = Math.ceil(this.page / 10)
            } else {
              this.adminList = [];
              // alert(res.data.err_msg);
            }
          })
          .catch(err => {
                console.log(err)
                localStorage.removeItem('accessToken')
                localStorage.removeItem('id')
                alert('세션이 만료되어 로그인 페이지로 이동합니다.')
                this.$router.push('/login')
          })
          .finally(()=>{
              this.loading = false;
          })
        }
    }
}
</script>
